import { defineStore } from 'pinia';

export const useWindowStore = defineStore('window', {
	state: () => {
		return {
			scrollY: 0,
			innerHeight: 1920,
			innerWidth: 1080,
			breakpointPhone: 480,
			breakpointMobile: 768,
			breakpointTablet: 1024,
			breakpointDesktop: 1280,
			deviceIs: 'mobile',
		};
	},
	actions: {
		updateScrollY(y) {
			this.scrollY = y;
		},
		updateHeight(h) {
			this.innerHeight = h;
		},
		updateWidth(w) {
			this.innerWidth = w;
		},
		updateDeviceIs() {
			if (this.innerWidth < this.breakpointPhone) {
				this.deviceIs = 'phone';
			} else if (this.innerWidth < this.breakpointMobile) {
				this.deviceIs = 'mobile';
			} else if (this.innerWidth < this.breakpointTablet) {
				this.deviceIs = 'tablet';
			} else if (this.innerWidth < this.breakpointDesktop) {
				this.deviceIs = 'desktop';
			} else {
				this.deviceIs = 'desktop';
			}
		},
	},
});
